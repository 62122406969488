import React, { ReactText, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { SSO_PROVIDER_LIST } from '../../helpers/sso-providers-helper';

export const SideMenu = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const subMenuKeys: Record<string, string[]> = {
    'single-sign-on': ['/single-sign-on', ...SSO_PROVIDER_LIST.map((v) => v.key)],
    'external-users': [
      '/external-users',
      '/external-users/ldap'
      // TODO: uncomment when Google Workspace is ready to use
      // '/external-users/google-workspace'
    ],
    rfid: ['/rfid', '/rfid/compatibility']
  };
  const defaultOpenKeys = Object.keys(subMenuKeys).some((v) => location.pathname === `/${v}`)
    ? [location.pathname]
    : [location.pathname.split('/').slice(0, -2).join('/'), location.pathname.split('/').slice(0, -1).join('/')].filter(
        (v) => v.length
      );
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);

  const onOpenChange = (newOpenKeys: string[]) => {
    if (newOpenKeys.length) {
      const latestOpenKey = newOpenKeys.find((key) => openKeys.indexOf(key) === -1);
      if (latestOpenKey) {
        const valid = Object.entries(subMenuKeys).reduce(
          (acc, current) => (current[1].indexOf(latestOpenKey) !== -1 ? current : acc),
          ['', []]
        );
        if (valid[1].length) {
          return setOpenKeys([valid[1][0], ...[latestOpenKey]]);
        }
      }
      setOpenKeys(newOpenKeys);
    }
  };

  return (
    <Menu
      mode="inline"
      theme="light"
      selectedKeys={[location.pathname]}
      openKeys={openKeys}
      onOpenChange={(val: ReactText[]) => onOpenChange(val as string[])}
      onSelect={() => {
        if (Object.keys(subMenuKeys).every((v) => !location.pathname.includes(v))) {
          setOpenKeys([]);
        }
        window.scrollTo(0, 0);
      }}
      style={{ height: '100%', borderRight: 0 }}>
      <Menu.Item key="/">
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="/changelogs">
        <Link to="/changelogs">Changelogs</Link>
      </Menu.Item>
      <Menu.Item key="/api">
        <Link to="/api">API</Link>
      </Menu.Item>
      <Menu.Item key="/user-guide">
        <Link to="/user-guide">User Guide</Link>
      </Menu.Item>
      <Menu.Item key="/fuyl-networking">
        <Link to="/fuyl-networking">FUYL Networking Guide</Link>
      </Menu.Item>
      <Menu.Item key="/service-ip-ranges">
        <Link to="/service-ip-ranges">Service IP Ranges</Link>
      </Menu.Item>
      <Menu.SubMenu
        key="/single-sign-on"
        title="Single Sign-On Guide"
        onTitleClick={() => {
          if (window.location.pathname !== '/single-sign-on') {
            history.push(`/single-sign-on`);
            window.scrollTo(0, 0);
          }
        }}>
        {SSO_PROVIDER_LIST.map((provider) => (
          <Menu.SubMenu key={provider.key} title={provider.title}>
            {provider.providerTypes.map((providerType) => (
              <Menu.Item key={providerType.key}>
                <Link to={providerType.key}>{providerType.title}</Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu
        key="/rfid"
        title="RFID Setup"
        onTitleClick={() => {
          if (window.location.pathname !== '/rfid') {
            history.push(`/rfid`);
            window.scrollTo(0, 0);
          }
        }}>
        <Menu.Item key="/rfid/compatibility">
          <Link to="/rfid/compatibility">RFID Compatibility</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="/external-users"
        title="External Users"
        onTitleClick={() => {
          if (window.location.pathname !== '/external-users') {
            history.push(`/external-users`);
            window.scrollTo(0, 0);
          }
        }}>
        <Menu.Item key="/external-users/ldap">
          <Link to="/external-users/ldap">LDAP</Link>
        </Menu.Item>
        {/* 
        TODO: uncomment when Google Workspace is ready to use
        <Menu.Item key="/external-users/google-workspace">
          <Link to="/external-users/google-workspace">Google Workspace</Link>
        </Menu.Item> */}
      </Menu.SubMenu>
      <Menu.Item key="/security-and-compliance">
        <Link to="/security-and-compliance">Security and Compliance</Link>
      </Menu.Item>
      <Menu.Item key="/webhooks">
        <Link to="/webhooks">Webhooks</Link>
      </Menu.Item>
    </Menu>
  );
};
