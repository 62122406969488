import Auth0OidcGuide from '../markdown/sso-providers/auth0-oidc-guide.md';
import Auth0SamlGuide from '../markdown/sso-providers/auth0-saml-guide.md';
import AzureOidcGuide from '../markdown/sso-providers/azure-oidc-guide.md';
import AzureSamlGuide from '../markdown/sso-providers/azure-saml-guide.md';
import OktaOidcGuide from '../markdown/sso-providers/okta-oidc-guide.md';
import OktaSamlGuide from '../markdown/sso-providers/okta-saml-guide.md';
import OneLoginOidcGuide from '../markdown/sso-providers/onelogin-oidc-guide.md';
import OneLoginSamlGuide from '../markdown/sso-providers/onelogin-saml-guide.md';
import PingIdentityOidcGuide from '../markdown/sso-providers/pingidentity-oidc-guide.md';
import PingIdentitySamlGuide from '../markdown/sso-providers/pingidentity-saml-guide.md';

interface SSOProvider {
  key: string;
  title: string;
  providerTypes: {
    key: string;
    title: string;
    markdown: string;
  }[];
}

export const SSO_PROVIDER_LIST: SSOProvider[] = [
  {
    key: '/single-sign-on/auth0',
    title: 'Auth0',
    providerTypes: [
      {
        key: '/single-sign-on/auth0/oidc',
        title: 'OIDC Guide',
        markdown: Auth0OidcGuide
      },
      {
        key: '/single-sign-on/auth0/saml',
        title: 'SAML Guide',
        markdown: Auth0SamlGuide
      }
    ]
  },
  {
    key: '/single-sign-on/azure',
    title: 'Microsoft Azure',
    providerTypes: [
      {
        key: '/single-sign-on/azure/oidc',
        title: 'OIDC Guide',
        markdown: AzureOidcGuide
      },
      {
        key: '/single-sign-on/azure/saml',
        title: 'SAML Guide',
        markdown: AzureSamlGuide
      }
    ]
  },
  {
    key: '/single-sign-on/okta',
    title: 'Okta',
    providerTypes: [
      {
        key: '/single-sign-on/okta/oidc',
        title: 'OIDC Guide',
        markdown: OktaOidcGuide
      },
      {
        key: '/single-sign-on/okta/saml',
        title: 'SAML Guide',
        markdown: OktaSamlGuide
      }
    ]
  },
  {
    key: '/single-sign-on/onelogin',
    title: 'OneLogin',
    providerTypes: [
      {
        key: '/single-sign-on/onelogin/oidc',
        title: 'OIDC Guide',
        markdown: OneLoginOidcGuide
      },
      {
        key: '/single-sign-on/onelogin/saml',
        title: 'SAML Guide',
        markdown: OneLoginSamlGuide
      }
    ]
  },
  {
    key: '/single-sign-on/pingidentity',
    title: 'Ping Identity',
    providerTypes: [
      {
        key: '/single-sign-on/pingidentity/oidc',
        title: 'OIDC Guide',
        markdown: PingIdentityOidcGuide
      },
      {
        key: '/single-sign-on/pingidentity/saml',
        title: 'SAML Guide',
        markdown: PingIdentitySamlGuide
      }
    ]
  }
];
