import Cloud1400 from './markdown/changelogs/cloud-1400.md';
import Cloud1410 from './markdown/changelogs/cloud-1410.md';
import Cloud1420 from './markdown/changelogs/cloud-1420.md';
import Firmware242 from './markdown/changelogs/firmware-242.md';
import Cloud1430 from './markdown/changelogs/cloud-1430.md';
import Cloud1440 from './markdown/changelogs/cloud-1440.md';
import Cloud1450 from './markdown/changelogs/cloud-1450.md';
import Cloud1460 from './markdown/changelogs/cloud-1460.md';
import Cloud1470 from './markdown/changelogs/cloud-1470.md';
import Cloud1471 from './markdown/changelogs/cloud-1471.md';
import Cloud1480 from './markdown/changelogs/cloud-1480.md';
import Cloud1490 from './markdown/changelogs/cloud-1490.md';
import Firmware250 from './markdown/changelogs/firmware-250.md';
import Cloud1500 from './markdown/changelogs/cloud-1500.md';
import Cloud1510 from './markdown/changelogs/cloud-1510.md';
import Firmware251 from './markdown/changelogs/firmware-251.md';
import Cloud1570 from './markdown/changelogs/cloud-1570.md';
import Cloud1590 from './markdown/changelogs/cloud-1590.md';
import Cloud1600 from './markdown/changelogs/cloud-1600.md';
import Cloud1640 from './markdown/changelogs/cloud-1640.md';
import Cloud1650 from './markdown/changelogs/cloud-1650.md';
import Cloud1660 from './markdown/changelogs/cloud-1660.md';
import Cloud1670 from './markdown/changelogs/cloud-1670.md';
import Cloud1690 from './markdown/changelogs/cloud-1690.md';
import Cloud1700 from './markdown/changelogs/cloud-1700.md';
import Firmware260 from './markdown/changelogs/firmware-260.md';
import Cloud1750 from './markdown/changelogs/cloud-1750.md';
import Firmware261 from './markdown/changelogs/firmware-261.md';
import Cloud1800 from './markdown/changelogs/cloud-1800.md';
import Cloud1990 from './markdown/changelogs/cloud-1990.md';
import Firmware281 from './markdown/changelogs/firmware-281.md';
import Firmware290 from './markdown/changelogs/firmware-290.md';
import Cloud11190 from './markdown/changelogs/cloud-11190.md';
/* NEW_CHANGELOG_TAG */

interface IChangelog {
  tagId: number;
  markdown: string;
}

export const CHANGELOG_LIST: IChangelog[] = [
  {
    tagId: 1,
    markdown: Cloud1400
  },
  {
    tagId: 2,
    markdown: Cloud1410
  },
  {
    tagId: 3,
    markdown: Cloud1420
  },
  {
    tagId: 4,
    markdown: Firmware242
  },
  {
    tagId: 5,
    markdown: Cloud1430
  },
  {
    tagId: 6,
    markdown: Cloud1440
  },
  {
    tagId: 7,
    markdown: Cloud1450
  },
  {
    tagId: 8,
    markdown: Cloud1460
  },
  {
    tagId: 9,
    markdown: Cloud1470
  },
  {
    tagId: 10,
    markdown: Cloud1471
  },
  {
    tagId: 11,
    markdown: Cloud1480
  },
  {
    tagId: 12,
    markdown: Cloud1490
  },
  {
    tagId: 13,
    markdown: Firmware250
  },
  {
    tagId: 14,
    markdown: Cloud1500
  },
  {
    tagId: 15,
    markdown: Cloud1510
  },
  {
    tagId: 16,
    markdown: Firmware251
  },
  {
    tagId: 17,
    markdown: Cloud1570
  },
  {
    tagId: 18,
    markdown: Cloud1590
  },
  {
    tagId: 19,
    markdown: Cloud1600
  },
  {
    tagId: 20,
    markdown: Cloud1640
  },
  {
    tagId: 21,
    markdown: Cloud1650
  },
  {
    tagId: 22,
    markdown: Cloud1660
  },
  {
    tagId: 23,
    markdown: Cloud1670
  },
  {
    tagId: 24,
    markdown: Cloud1690
  },
  {
    tagId: 25,
    markdown: Cloud1700
  },
  {
    tagId: 26,
    markdown: Firmware260
  },
  {
    tagId: 27,
    markdown: Cloud1750
  },
  {
    tagId: 28,
    markdown: Firmware261
  },
  {
    tagId: 29,
    markdown: Cloud1800
  },
  {
    tagId: 30,
    markdown: Cloud1990
  },
  {
    tagId: 31,
    markdown: Firmware281
  },
  {
    tagId: 32,
    markdown: Firmware290
  },
  {
    tagId: 33,
    markdown: Cloud11190
  } /* NEW_CHANGELOG_ITEM */
];
