import React, { ReactNode } from 'react';
import { Col, Row, Tag } from 'antd';
import Markdown from 'markdown-to-jsx';
import {
  BugOutlined,
  CloudOutlined,
  DatabaseOutlined,
  NotificationOutlined,
  StarOutlined,
  ToolOutlined
} from '@ant-design/icons';
import { CHANGELOG_LIST } from '../../changelog-list';
import { isBrandPCLocs, brandName } from '../../helpers/brand-helper';

enum ChangelogContext {
  CLOUD = 'cloud',
  FIRMWARE = 'firmware'
}

enum ChangelogItemContext {
  BUGFIX = 'bugfix',
  NEW_FEATURES = 'new-features',
  UPCOMING = 'upcoming',
  IMPROVEMENTS = 'improvements'
}

interface IChangelog {
  type: ChangelogContext;
  tag: number;
  children: ReactNode;
}

interface IChangelogItem {
  type: ChangelogItemContext;
  children: ReactNode;
}

const CHANGELOG_CONTEXTS: { [key in ChangelogContext]: JSX.Element } = Object.freeze({
  [ChangelogContext.CLOUD]: <CloudOutlined />,
  [ChangelogContext.FIRMWARE]: <DatabaseOutlined />
});

const CHANGELOG_ITEM_CONTEXTS: { [key in ChangelogItemContext]: JSX.Element } = Object.freeze({
  [ChangelogItemContext.UPCOMING]: (
    <>
      <NotificationOutlined /> Upcoming
    </>
  ),
  [ChangelogItemContext.NEW_FEATURES]: (
    <>
      <StarOutlined /> New features and updates
    </>
  ),
  [ChangelogItemContext.BUGFIX]: (
    <>
      <BugOutlined /> Bugfixes
    </>
  ),
  [ChangelogItemContext.IMPROVEMENTS]: (
    <>
      <ToolOutlined /> Improvements
    </>
  )
});

export const Changelogs = (mainProps: { targetTag?: number }): JSX.Element => {
  const isTrimmed = !!mainProps.targetTag;
  const changelogList = CHANGELOG_LIST.sort((a, b) => b.tagId - a.tagId)
    .slice(0, isTrimmed ? 5 : undefined)
    .map((v) => v.markdown.replace(/tag/, `tag="${v.tagId}"`))
    .join('<br />');
  return (
    <Row className={isTrimmed ? 'trimmed' : ''}>
      <Col span="20" offset="2">
        {!isTrimmed && <h1>Changelogs</h1>}
        <Markdown
          className="markdown-doc"
          options={{
            overrides: {
              Heading: {
                component: function Heading(props: IChangelog) {
                  return (
                    <h2 style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                      {CHANGELOG_CONTEXTS[props.type]}&nbsp;
                      {props.children}
                      {mainProps.targetTag && mainProps.targetTag < props.tag && (
                        <>
                          &nbsp;<Tag color="#39b54a">NEW</Tag>
                        </>
                      )}
                    </h2>
                  );
                }
              },
              ChangelogItem: {
                component: function ChangelogItem(props: IChangelogItem) {
                  return (
                    <>
                      <h3 style={{ marginTop: 0 }}>{CHANGELOG_ITEM_CONTEXTS[props.type]}</h3>
                      {props.children}
                    </>
                  );
                }
              },
              List: {
                component: function List(props: { children: ReactNode }) {
                  return <ul style={{ paddingInlineStart: '20px', marginTop: '10px' }}>{props.children}</ul>;
                }
              },
              BrandName: {
                component: function BrandName() {
                  return <>{brandName()}</>;
                }
              },
              TermsOfServiceLink: {
                component: function TermsOfServiceLink(props: { children: ReactNode }) {
                  const tosLink = isBrandPCLocs()
                    ? 'https://www.pclocs.com.au/terms-of-service'
                    : 'https://www.lockncharge.com/terms-of-service';
                  return (
                    <a href={tosLink} target="_blank" rel="noopener noreferrer">
                      {props.children}
                    </a>
                  );
                }
              }
            }
          }}>
          {changelogList}
        </Markdown>
      </Col>
    </Row>
  );
};
