import React from 'react';
import pcLocsLogo from '../../assets/images/pc-locs.png';
import locknchargeLogo from '../../assets/images/lockncharge.png';
import { isBrandPCLocs } from '../../helpers/brand-helper';
import { Link } from 'react-router-dom';

export const Logo = (props: { width?: number }): JSX.Element => {
  const isPCLocs = isBrandPCLocs();
  const src: string = isPCLocs ? pcLocsLogo : locknchargeLogo;
  const alt = isPCLocs ? 'PC Locs logo' : 'LocknCharge logo';
  return (
    <Link to="/">
      <img className="logo" src={src} alt={alt} width={props.width ? props.width : ''} />
    </Link>
  );
};
