import React from 'react';
import { brandName } from '../../helpers/brand-helper';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'antd';

export const Home = (): JSX.Element => {
  const brand = brandName();
  const linkCards = [
    [
      {
        description: 'Read the changelogs for Cloud and Firmware updates.',
        link: '/changelogs',
        title: 'Changelogs'
      },
      {
        description: 'Read the documentation for the Cloud Integration API.',
        link: '/api',
        title: 'API'
      }
    ],
    [
      {
        description: 'Read the user guide on Cloud portal and station.',
        link: '/user-guide',
        title: 'User Guide'
      },
      {
        description: 'Read the networking and troubleshooting guide for FUYL Towers.',
        link: '/fuyl-networking',
        title: 'FUYL Networking Guide'
      }
    ],
    [
      {
        description: 'Read the documentation for the service IP ranges supported in the Cloud.',
        link: '/service-ip-ranges',
        title: 'Service IP Ranges'
      },
      {
        description: 'Read the documentation guide for configuring Single Sign-On in the Cloud Portal.',
        link: '/single-sign-on',
        title: 'Single Sign-On Guide'
      }
    ],
    [
      {
        description: 'Read the RFID setup guide for FUYL Towers.',
        link: '/rfid',
        title: 'RFID Setup'
      },
      {
        description: 'Read the documentation guide for configuring External Users in the Cloud Portal.',
        link: '/external-users',
        title: 'External Users'
      }
    ],
    [
      {
        description: 'Read the documentation for Security and Compliance at PC Locs.',
        link: '/security-and-compliance',
        title: 'Security and Compliance'
      },
      {
        description: 'Read the documentation guide for configuring Webhooks in the Cloud Portal.',
        link: '/webhooks',
        title: 'Webhooks'
      }
    ]
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={{ span: 16, offset: 4 }}>
          <h1>Welcome to the {brand} documentation site</h1>
          <p style={{ marginBottom: 60 }}>
            Here you can find documentation related to {brand} Cloud platform and connected devices.
          </p>
        </Col>
      </Row>

      {linkCards.map((r, index) => (
        <Row gutter={16} style={{ marginBottom: 20 }} key={index}>
          {r.map((c, i) => (
            <Col xs={24} md={{ span: 8, offset: i === 0 ? 4 : 0 }} key={i}>
              <Link to={c.link}>
                <Card
                  className="home-nav-card"
                  title={c.title}
                  bordered={true}
                  hoverable
                  headStyle={{ background: '#fbfbfb' }}>
                  {c.description}
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};
