import { Alert, Col, Layout, Row, Space, Tag } from 'antd';
import { SideMenu } from './components/SideMenu';
import 'rapidoc';
import React, { ReactNode, useEffect, useState } from 'react';
import './App.less';
import { Logo } from './components/Logo';
import { brandName, isBrandPCLocs } from './helpers/brand-helper';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { Home } from './components/Home';
import { Changelogs } from './components/Changelogs';
import { API } from './components/API';
import Markdown from 'markdown-to-jsx';
import { InfoCircleOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import FUYLNetworkingGuide from './markdown/networking-guide.md';
import SSOProvidersGuide from './markdown/sso-providers/sso-providers-guide.md';
import ServiceIpRanges from './markdown/service-ip-ranges.md';
import { SSO_PROVIDER_LIST } from './helpers/sso-providers-helper';
import { CHANGELOG_LIST } from './changelog-list';
import RfidCompatibility from './markdown/rfid.md';
import UserGuide from './markdown/user-guide.md';
import Ldap from './markdown/ldap.md';
// import Google from './markdown/google.md'; <- TODO: uncomment when Google Workspace is ready to use
import ExternalUsers from './markdown/external-users.md';
import RfidSetup from './markdown/rfid-setup.md';
import SecurityAndCompliance from './markdown/security-and-compliance.md';
import Webhooks from './markdown/webhooks.md';
import Hotjar from '@hotjar/browser';

const { Header, Content, Sider } = Layout;

const DEV_DOC_ORIGINS = ['http://localhost:3000', 'https://docs.dev.pclocs.io', 'https://docs.dev.lockncharge.io'];

const App = (): JSX.Element => {
  const [isCollapsed, setCollapsed] = useState<boolean>(false);
  const [originChangelogTag, setOriginChangelogTag] = useState<number>();

  const devHotjarSiteId = 3160953;
  const prodHotjarSiteId = 3160947;

  const siteId = ['docs.pclocs.io', 'docs.lockncharge.io'].some((v) => v === window.location.hostname)
    ? prodHotjarSiteId
    : devHotjarSiteId;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  useEffect(() => {
    document.title = `${brandName()} Cloud Documentation`;

    function handleMessage(event: MessageEvent) {
      // allow local portal to retrieve changelogs from dev docs
      const isEventOriginLocal =
        event.origin === 'http://localhost:4200' && DEV_DOC_ORIGINS.includes(window.location.origin);

      if ((!isEventOriginLocal && window.location.origin.replace(/docs\./, '') !== event.origin) || !event.data) {
        return;
      }
      setOriginChangelogTag(event.data);
      const latestChangelogTag = Math.max(...CHANGELOG_LIST.map((v) => v.tagId));
      (event.source as Window).postMessage(latestChangelogTag, event.origin);
    }
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const MarkdownComponent = ({ markdown }: { markdown: string }): JSX.Element => (
    <Row>
      <Col span="20" offset="2">
        <Markdown
          className="markdown-doc"
          options={{
            overrides: {
              Alert: { component: Alert },
              InfoCircleOutlined: { component: InfoCircleOutlined },
              BrandName: {
                component: function BrandName() {
                  return <>{brandName()}</>;
                }
              },
              SSOSignInLink: {
                component: function SSOSignInLink() {
                  const domain = isBrandPCLocs() ? 'pclocs' : 'lockncharge';
                  return (
                    <a href={`https://${domain}.io/login/sso-sign-in`} target="_blank" rel="noopener noreferrer">
                      sign-in with SSO
                    </a>
                  );
                }
              },
              UserManualLink: {
                component: function UserManualLink(props: { children: ReactNode }) {
                  return (
                    <a
                      href="https://cdn.bfldr.com/3VZ9CGUT/as/6xc728kz6bms2n5kk2kkw97/PCL-LNC-Cloud-Portal-Users-Manual10"
                      target="_blank"
                      rel="noopener noreferrer">
                      {props.children}
                    </a>
                  );
                }
              },
              AdvancedTierTag: {
                component: function ServiceIpRangesLink(props: { children: ReactNode }) {
                  return <Tag color="green">Advanced Tier</Tag>;
                }
              }
            }
          }}>
          {markdown}
        </Markdown>
      </Col>
    </Row>
  );

  return originChangelogTag ? (
    <Changelogs targetTag={originChangelogTag} />
  ) : (
    <Router>
      <Layout>
        <Header className="header-nav">
          <Logo />
          <Space>Documentation</Space>
        </Header>
        <Layout hasSider>
          <Sider
            className="side-menu"
            trigger={isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            theme="light"
            breakpoint="lg"
            collapsible
            collapsedWidth="0"
            width={220}
            onCollapse={(collapsed) => {
              setCollapsed(collapsed);
            }}>
            <SideMenu />
          </Sider>
          <Content className="main-content" style={{ marginLeft: isCollapsed ? 0 : 220 }}>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/changelogs">
                <Changelogs />
              </Route>
              <Route path="/api">
                <Row>
                  <API />
                </Row>
              </Route>
              <Route path="/user-guide">
                <MarkdownComponent markdown={UserGuide} />
              </Route>
              <Route path="/security-and-compliance">
                <MarkdownComponent markdown={SecurityAndCompliance} />
              </Route>
              <Route path="/fuyl-networking">
                <MarkdownComponent markdown={FUYLNetworkingGuide} />
              </Route>
              <Route path="/service-ip-ranges">
                <MarkdownComponent markdown={ServiceIpRanges} />
              </Route>
              <Route path="/rfid/compatibility">
                <MarkdownComponent markdown={RfidCompatibility} />
              </Route>
              <Route exact path="/external-users">
                <MarkdownComponent markdown={ExternalUsers} />
              </Route>
              {/* 
              TODO: uncomment when Google Workspace is ready to use
              <Route path="/external-users/google-workspace" key="/external-users/google-workspace">
                <MarkdownComponent markdown={Google} />
              </Route>
              */}
              <Route path="/external-users/ldap" key="/external-users/ldap">
                <MarkdownComponent markdown={Ldap} />
              </Route>
              <Route path="/rfid">
                <MarkdownComponent markdown={RfidSetup} />
              </Route>
              <Route path="/webhooks">
                <MarkdownComponent markdown={Webhooks} />
              </Route>
              <Switch>
                <Route exact path="/single-sign-on">
                  <MarkdownComponent markdown={SSOProvidersGuide} />
                </Route>
                {SSO_PROVIDER_LIST.map((provider) => provider.providerTypes)
                  .flat()
                  .map((providerType) => (
                    <Route key={providerType.key} path={providerType.key}>
                      <MarkdownComponent markdown={providerType.markdown} />
                    </Route>
                  ))}
              </Switch>
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
