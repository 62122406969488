import React from 'react';
import { Col, Tag } from 'antd';

const serverUrl = `https://${window.location.hostname.replace(/docs/g, 'api')}`;

export const API = (): JSX.Element => {
  return (
    <Col xs="24" xxl={{ span: 16, offset: 4 }}>
      <h1>
        API <Tag color="green">Advanced Tier</Tag>
      </h1>
      <rapi-doc
        spec-url={process.env.PUBLIC_URL + '/publicapi.json'}
        server-url={serverUrl}
        show-header="false"
        schema-style="table"
        show-info="false"
        fill-request-fields-with-example="false"
        allow-server-selection="false"
        style={{ minWidth: 1100 }}
      />
    </Col>
  );
};
